.breadcrumb {
    background-color: $color-bg--neutral;

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 30px;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-text;
        padding: 0 5px 0 0;

        & svg {
            width: 20px;
            height: 20px;
        }

        // "Vous êtes ici" style
        //&:first-child {
        //    font-size: $font-size--text-small;
        //    font-weight: $font-weight-bold;
        //    text-transform: uppercase;
        //}

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
        } 
    }

}
