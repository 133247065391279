//======================================================================================================
// Actions pages
//======================================================================================================
@keyframes show-hide {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.actions-post {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
    width: 960px;
    height: 80px;
    margin: 60px auto -40px;
    background-color: white;
    box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);
    border-radius: 10px;

    li {
        position: relative;

        a, button, div {
            display: flex;
            align-items: center;
            cursor: pointer;
            height: 60px;
            margin: 0;
            padding: 0 30px;
            background-color: transparent;
            border: none;
            font-family: $font-family;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            color: $color-text;

            svg {
                width: 26px;
                height: 26px;
                margin-right: 14px;
                fill: $color-text;
                background-color: $color-third;
                border-radius: $border-radius--round;
                transition: all $duration ease-in-out;
            }

            &:hover {

                svg {
                    fill: $color-white;
                    background-color: $color-main-bg;
                }
            }


        }

        &:last-child a,
        &:last-child button {
            border-right: 0;
            transition: none;
        }

    }

    .share-list {
        display: none;
    }

    &.shared-on {

        .action-share {
            opacity: 0;
        }

        .share-list {
            display: flex;
            position: absolute;
            top: 10px;
            left: 30px;

            li {
                margin-right: 10px;

                &:first-child {
                    a, button, div {
                        transition: background-color ease-in-out $duration;

                        &:hover, &:focus {
                            transition: background-color ease-in-out $duration;
                        }
                    }
                }
            }

            a, button, div {

                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                width: 40px;
                height: 40px;
                padding: 0;
                background-color: $color-main-bg;
                border: 0;
                border-radius: $border-radius--round;
                box-shadow: $shadow;
                opacity: 1;

                &:hover, &:focus {
                    background-color: $color-third;
                    border: 0;

                    svg {
                        fill: $color-text;
                    }

                }

                svg {
                    margin: 0;
                    fill: $color-white;
                    transform: scale(1.3);
                    background-color: $color-bg--transparent;
                }

                &.close {
                    svg {
                        transform: scale(1.5);
                        // stroke: $color-white;
                        fill: $color-white;
                    }
                    &:hover, &:focus {
                        svg {
                            fill: $color-text;
                            // stroke: $color-text;
                        }
                    }
                }

                #post_url {
                    position: absolute;
                    opacity: 0;
                }
            }

            .copy-confirm {
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 35px;
                left: 50%;
                z-index: 2;
                width: 105px;
                height: 30px;
                opacity: 0;
                background: $color-gray;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: none;
                color: $color-white;
                transform: translate(-50%, 0);

                &.show-hide {
                    animation : show-hide 2s;
                }
            }

        }
    }

}

section[data-cpt=maps] .actions-post {
    margin-top: 0;
}

//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .actions-post {
        width: 740px;

        &.shared-on {

            .share-list {
                display: flex;
                position: absolute;
                top: -60px;
                left: -60px;
                filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.15));
            }

            .action-share {
                opacity: 1;
            }

        }
    }

}


// 640
@media screen and (max-width: $small) {

    .actions-post {
        width: 300px;

        > li {
            > a, button, div {
                justify-content: center;
                width: 40px;
                height: 40px;
                margin: 0 20px;
                padding: 0;
                border-radius: 50px;

                span {
                    display: none;
                }
                svg {
                    margin: 0;
                }
            }
        }

        &.shared-on {
            .share-list {
                left:-185px;
                top: -70px;

                button {
                    margin: 0;
                }
            }
        }

    }

}
