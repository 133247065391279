.front-page {

    .section-double {
        display: flex;
        justify-content: space-between;
    }
}

//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        z-index: 2;
        height: 62px;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url('/wp-content/themes/saclay/assets/src/images/sprite-images/Pictos/Vague.svg');
        background-position-x: center;
        background-size: cover;
        pointer-events: none;
    }

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--pagination {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            pointer-events: none
        }
    }

    .slideshow {
        height: 520px;
        width: calc(50% + 185px);
        margin-right: 0;

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;

            &--link {
                
    
                &:hover, &:focus, &:focus-within {
                    .slideshow {
                        &__content {
                            background-color: rgba(255, 255, 255, 0.9);
                            padding: 30px 20px 50px;
                        }
                    }
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: absolute;
            left: 270px;
            bottom: 0;
            width: 290px;
            height: auto;
            padding: 30px 20px;
            background: rgba(255, 255, 255, 0.8);;
            color: $color-text;
            border-radius: $border-radius;
            transition: all $duration ease-in-out;
            
            & svg {
                width: 25px;
                height: 25px;
                border: 1px solid $color-dark;
                border-radius: $border-radius--round;
                margin: 0 auto;
                fill: $color-dark;
                transition: all $duration ease-in-out;

                &:hover {
                    background-color: $color-dark;
                    fill: $color-white;
                }
            }
        }

        &__title {
            text-align: center;
            margin-bottom: 10px;
            font-size: 1.5rem;
            line-height: 1.875rem;
            font-weight: $font-weight-bold;
            font-family: $font-family--heading;
        }

        &--video {
            img {
                display: none;
            }
        }

        // Pagination (bullet)
        &__pagination {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            left: 0;
            bottom: 50px;
            z-index: 2;
            width: 770px;
            filter: drop-shadow(1px 1px 20px #1B2336);

    
            .swiper-pagination-bullet {
                width: 18px;
                height: 18px;
                margin: 0 15px 0 0;
                transition: .25s;
                background-color: $color-white;
                border: 1px solid $color-white;
                border-radius: $border-radius--round;
                pointer-events: auto;
                opacity: 1;
    
                &:hover, &:focus, &:focus-within,    
                &-active {
                    background-color: $color-bg--transparent;
                    width: 16px;
                    height: 16px;
                    border-width: 2px;
                }

                &:last-child {
                    margin: 0;
                }
            }
        }
    
    }
}



//======================================================================================================
// Access
//======================================================================================================

.access {

    &__section {
        margin-top: -20px;
        z-index: 3;
        position: relative;
    }

    &__list {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        height: 250px;
    }

    &__link {
        display: inline-block;
        text-align: center;

        & svg {
            border-radius: $border-radius--round;
            background-color: $color-second--rgba;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus, &:focus-within {

            & svg {
                background-color: rgba(117,192,68,.5);
            }

            .access__info {
                margin-top: 10px;
            }
        }
    }

    &__info {
        font-size: 1.125rem;
        line-height: 1.375rem;
        font-weight: $font-weight-semibold;
        font-family: $font-family--heading;
        margin-top: 15px;
        transition: all $duration ease-in-out;
    }
}


//======================================================================================================
// Event
//======================================================================================================
.events {

    &__section {
        margin-top: 75px;
        margin-bottom: 70px;
    }

    &__heading {
        &__container {
            position: relative;
        }
    }

    &__container {
        position: relative;
        overflow: hidden;

        .swiper-container {
            height: 430px;
            overflow: unset;

            .swiper-slide {
                width: 370px;
                margin-right: 30px;
            }
        }

        &--pagination {
            position: relative;
        }
    }

    &__heading {
        position: relative;
        z-index: 0;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: -100px;
            width: 300px;
            height: 300px;
            border-radius: $border-radius--round;
            background-color: $color-third--rgba;
        }
    }

    &__title {
        display: flex;
        flex-direction: column;
        font-size: 3rem;
        line-height: 3.438rem;
        color: $color-text;
        font-weight: $font-weight-light;
        margin: 0 0 35px;
        padding: 0;

        & svg {
            position: absolute;
            bottom: -13px;
            width: 64px;
            height: 8px;
            margin-top: 5px;
            fill: $color-third;
        }

        &:before {
            content: none;
        }
    }

    &__link {
        position: relative;
        display: inline-block;
        height: 410px;
        width: 370px;
        border-radius: $border-radius;
        overflow: hidden;
        background-color: $color-white;
        //filter: drop-shadow(0px 0px 1px rgba(27, 35, 54, 0.08)) drop-shadow(1px 1px 8px rgba(27, 35, 54, 0.08));
        box-shadow: $shadow;
        transition: all $duration ease-in-out;

        &:hover, &:focus, &:focus-within {
            box-shadow: none;

            .events {
                &__content {
                    & img {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }

    &__content {
        height: 210px;

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-bg--image;
            background-position: center;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(1);
            transition: all $duration ease-in-out;
        }
    }

    &__date {
        display: flex;
        z-index: 1;
        align-items: center;
        position: absolute;
        top: 170px;
        left: 20px;
        height: 100px;
        
        & p {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            z-index: 0;
            width: 100px;
            height: 100px;

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                width: 100px;
                height: 100px;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                background-color: $color-third;
                border-radius: $border-radius--round;
            }
        }

        &__double {
            z-index: -1!important;
            margin-left: -10px;
            color: $color-white;

            &:before {
                background-color: $color-main!important;
            }
        }

        &  svg {
            position: absolute;
            z-index: 1;
            left: 90px;
            width: 20px;
            height: 20px;
            background-color: white;
            border-radius: $border-radius--round;
        }
        
        &__day {
            font-size: 2rem;
            line-height: 2rem;
            font-weight: $font-weight-bold;
            font-family: $font-family--heading;
        }

        &__month {
            font-size: 1.125rem;
            text-transform: uppercase;
            font-family: $font-family--heading;
        }
    }

    &__infos {
        padding: 80px 15px 15px;

        &__title {
            height: 65px;
            margin: 0;
            padding: 0 15px 10px;
            color: $color-text;
            font-weight: $font-weight-semibold;

            &:before {
                content: none;
            }
        }

        &__button {
            display: flex;
            width: 25px;
            height: 25px;
            padding: 0;
            margin: 0;
            margin-left: auto;
            background-color: $color-bg--transparent;
            border-color: $color-third;
            border-width: 1px;
            transition: all $duration ease-in-out;

            & svg {
                fill: $color-third;
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus, &:focus-within {
                background-color: $color-third;
                border-color: $color-third;
                
                & svg {
                    fill: $color-text;
                }
            }
        }
    }

    &__button {
        display: flex;
        align-items: center;
        width: 300px;
        margin: 0 0 0  auto;

        & svg  {
            margin-right: 8px;
        }
    }

    // Pagination (bullet)
    &__pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 370px;
        height: 60px;

        .swiper-pagination-bullet {
            width: 18px;
            height: 18px;
            margin: 0 15px 0 0;
            transition: .25s;
            background-color: $color-main;
            border: 1px solid $color-main;
            border-radius: $border-radius--round;
            opacity: 1;

            &:hover, &:focus, &:focus-within,
            &-active {
                background-color: $color-bg--transparent;
                width: 16px;
                height: 16px;
                border-width: 2px;
            }
        }
    }
}


//======================================================================================================
// Post
//======================================================================================================
.post {

    &__section {
        position: relative;
        padding: 70px 0 100px;
        background-color: $color-main-bg;

        &:before {
            content: "";
            position: absolute;
            z-index: 2;
            height: 62px;
            width: 100%;
            bottom: 0;
            left: 0;
            right: 0;
            background-image: url('/wp-content/themes/saclay/assets/src/images/sprite-images/Pictos/Vague.svg');
            background-position-x: center;
            background-size: cover;
            pointer-events: none;
        }
    }

    &__container {
        position: relative;
        z-index: 0;

        &--pagination {
            position: relative;
        }

        .swiper-container {
            height: 390px;
            margin-bottom: 10px;
        }

        &:before, &:after {
            content: "";
            position: absolute;
            z-index: -1;
            width: 300px;
            height: 300px;
            background-color: $color-white;
            opacity: .1;
            border-radius: $border-radius--round;
        }

        &:before {
            left: -130px;
            top: 45px;
        }

        &:after {
            right: -125px;
            bottom: -210px;
        }
    }

    &__heading {
        position: absolute;
        width: 470px;
        right: 15px;
        top: 30px;
        color: $color-white;
    }

    &__title {
        display: flex;
        flex-direction: column;
        font-size: 3rem;
        line-height: 3.438rem;
        padding: 0;
        color: $color-white;
        font-weight: $font-weight-light;

        & svg {
            position: absolute;
            bottom: -13px;
            width: 64px;
            height: 8px;
            fill: $color-fourth;
        }

        &:before {
            content: none;
        }
    }

    &__content {
        width: 670px;
        height: 390px;
        overflow: hidden;
        border-radius: $border-radius;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-bg--image;
            background-position: center;
        }
    }

    &__infos {
        margin-top: 130px;
        width: 470px;
        margin-left: 30px;
        color: white;

        &__title {
            color: $color-white;
            margin-bottom: 10px;

            &:before {
                content: none;
            }
        }

        &__introduction {
            margin-bottom: 15px;
        }

        &__button {
            margin: 0;
            padding: 0;
            color: $color-white;
            border: 0;
            background-color: $color-bg--transparent;

            & svg {
                margin-right: 10px;
                fill: $color-fourth;
                border: 1px solid $color-fourth;
                border-radius: $border-radius--round;
                background-color: $color-bg--transparent;
            }

            &:hover, &:focus, &:focus-within {
                color: $color-fourth;

                & svg {
                    fill: $color-dark;
                    background-color: $color-fourth;
                }
            }
        }
    }

    &__link {
        display: inline-flex;
    }

    // Pagination (bullet)
    &__pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 2;
        width: 670px;
        padding-left: 15px;
        height: 60px;

        .swiper-pagination-bullet {
            width: 18px;
            height: 18px;
            margin: 0 15px 0 0;
            transition: .25s;
            background-color: $color-white;
            border: 1px solid $color-white;
            border-radius: $border-radius--round;
            opacity: 1;

            &:hover, &:focus, &:focus-within,
            &-active {
                width: 16px;
                height: 16px;
                border-width: 2px;
                background-color: $color-bg--transparent;
            }
        }
    }

    &__buttons {
        text-align: right;
    }

    &__button {
        margin: 0;
        width: 300px;
        background-color: $color-fourth;
        border-color: $color-fourth;
        color: $color-text;

        & svg {
            fill: $color-text;
            margin-right: 8px;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus, &:focus-within {
            background-color: $color-bg--transparent;
            border-color: $color-fourth;
            color: $color-fourth;

            & svg {
                fill: $color-fourth;
            }
        }
    }
}


//======================================================================================================
// Press
//======================================================================================================
.press {

    &__section {
        @include flex($direction: column);
        margin-bottom: 70px;
        gap: 35px;
    }

    &__heading {
        width: 100%;
    }
    
    &__container {
        @include flex($direction: row, $wrap: wrap, $alignItems: flex-start);
        gap: 30px;
    }

    &__title {
        @include flex($direction: column);
        font-size: 3rem;
        line-height: 3.438rem;
        color: $color-text;
        font-weight: $font-weight-light;
        margin: 0;
        padding: 0;
        
        &:before {
            content: none;
        }
    
        & svg {
            position: absolute;
            bottom: -13px;
            width: 64px;
            height: 8px;
            fill: $color-fourth;
        }
    }

    &__subtitle {
        margin: -25px 0 0 50px;
    }

    &__item {
        position: relative;
        @include flex($direction: column);
        gap: 10px;
        @include size(calc(50% - 215px), auto);

        picture {
            display: inline-block;
            border-radius: $border-radius;
            overflow: hidden;
            @include size(100%);

            img {
                display: block;
                @include size(100%, 230px);
                object-fit: cover;
                object-position: center;
                transform: scale(1);
                transition: transform $duration ease-in-out;
            }
        }

        &:hover, &:focus {
            img {
                transform: scale(1.05);
            }
        }
    }

    &__ext {
        @include absolute($top: 10px, $right: 10px);
        z-index: 1;
        @include size(26px);
        fill: $color-text;
        background-color: $color-white;
        border-radius: $border-radius--round;
    }

    &__content {
        @include flex($direction: column);
        gap: 5px;
    }

    &__name {
        font-size: toRem(22);
        line-height: toRem(24);
        font-weight: $font-weight-extrabold;
        color: $color-text;
        margin: 0;
        padding: 0;

        &:before {
            content: none;
        }
    }

    &__desc {
        font-size: toRem(16);
        line-height: toRem(24);
        font-style: italic;
        color: $color-gray;
    }
}


//======================================================================================================
// Kiosque
//======================================================================================================
.kiosque {

    &__section {
        width: 760px;
    }

    &__title {
        display: flex;
        flex-direction: column;
        font-size: 3rem;
        line-height: 3.438rem;
        color: $color-text;
        font-weight: $font-weight-light;
        margin: 5px 0 50px;
        padding: 0;
        
        &:before {
            content: none;
        }
    
        & svg {
            position: absolute;
            bottom: -13px;
            width: 64px;
            height: 8px;
            fill: $color-fourth;
        }
    }

    &__info {

        &__title {
            font-size: 1.375rem;
            line-height: 1.5rem;
            margin: 15px 0 20px;
            padding: 0;
            color: $color-text;

            &:before {
                content: none;
            }
        }

        &__content {
            margin: 0 0 30px 0;
        }
    }

    &__items {
        display: flex;
        gap: 40px; 
    }

    &__item {
        display: flex;
    }

    &__image {
        width: 170px;
        height: 240px;
        border-radius: 4px;
        overflow: hidden;

        &--no-image {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-bg--image;
        
            svg {
              width: 40%;
            }
        }

        & img {
            width: 100%;
            height: 240px;
            object-fit: cover;
        }
    }

    &__content {
        width: 170px;
        margin-left: 20px;
    }

    &__filesize {
        text-transform: uppercase;
    }

    &__btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border: 1px solid $color-main;
        border-radius: $border-radius--round;
        transition: all $duration ease-in-out;

        & svg {
            fill: $color-main;
            width: 40px;
            height: 40px;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus, &:focus-within {
            background-color: $color-main;

            & svg {
                fill: $color-white;
            }
        }
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        width: 170px;
    }

    &__button {
        width: 270px;
        padding: 15px;
        margin: 50px 0 0 0;
        border-color: $color-main;
        background-color: $color-main;
        color: $color-white;
        transition: all $duration ease-in-out;

        & svg {
            margin-right: 8px;
            fill: $color-white;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus, &:focus-within {
            background-color: $color-bg--transparent;
            color: $color-text;

            & svg {
                fill: $color-text;
            }
        }
    }
}


//======================================================================================================
// Annuaire
//======================================================================================================
.section-double {
    position: relative;
    z-index: 0;
        
    &:before{
        content: "";
        position: absolute;
        z-index: -1;
        width: 300px;
        height: 300px;
        right: -130px;
        bottom: -45px;
        border-radius: $border-radius--round;
        background-color: $color-second--rgba;
    }
}

.annuaire {

    &__section {
        width: 372px;
        height: 380px;
        padding: 25px 30px 35px;
        margin-top: 60px;
        // margin-left: 45px;
        background-color: $color-white;
        box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);
        border-radius: $border-radius;
    }

    &__title {
        display: flex;
        flex-direction: column;
        font-size: 3rem;
        line-height: 3.438rem;
        font-weight: $font-weight-light;
        color: $color-text;
        margin: 0 0 30px 0;
        padding: 0;

        &:before {
            content: none;
        }

        & svg {
            position: absolute;
            bottom: -13px;
            width: 64px;
            height: 8px;
            fill: $color-second;
        }
    }

    &__content {
        & label {
            font-size: 1.25rem;
            line-height: 1.625rem;
            font-weight: $font-weight-bold;
            margin: 0 0 10px 0;
        }
    }

    &__wrapper-select {
        display: flex;
        position: relative;
        margin-bottom: 20px;

        & svg {
            position: absolute;
            width: 30px;
            height: 60px;
            top: 0;
            bottom: 0;
            right: 70px;
            pointer-events: none;
        }

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            cursor: pointer;
        }
    }

    &__label {
        display: inline-block;
        font-size: 0.938rem;
        line-height: 1.25rem;
        margin-bottom: 10px;
        font-weight: $font-weight-bold;
    }

    &__dropdown {
        font-size: 0.875rem;
        line-height: 1.5rem;
        padding: 0 20px;
        border-radius: 30px 0 0 30px;
        border-color: $color-second;
    }

    &__btn {    
        width: 55px;
        height: 60px;    
        margin: 0;
        border-radius: 0 30px 30px 0;
        background-color: $color-second;
        border-color: $color-second;
        color: $color-text;
        transition: all $duration ease-in-out;

        &:hover, &:focus, &:focus-within {
            background-color: $color-main;
            border-color: $color-main;
            color: $color-white;
        }
    }

    &__template {
        &-btn {
            width: 100%;
            background-color: $color-second;
            border-color: $color-second;
            margin: 0;

            & svg {
                margin-right: 8px;
            }
        }
    }
}


//======================================================================================================
// Keep Contact
//======================================================================================================
.keep-contact {
    
    &__container {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1170px;
        height: 150px;
        margin-top: 70px;
        margin-bottom: -75px;
        border-radius: $border-radius;
        background-color: $color-white;
        overflow: hidden;
        filter: drop-shadow(0px 0px 1px rgba(27, 35, 54, 0.08)) drop-shadow(1px 1px 10px rgba(27, 35, 54, 0.08));
    }

    &__list {
        display: flex;
        width: 850px;
        gap: 115px;
        padding-left: 25px;
    }

    &__title {
        position: relative;
        z-index: 0;
        font-size: 2.5rem;
        line-height: 2.5rem;
        margin-left: 25px;
        color: $color-text;
        font-weight: $font-weight-light;
        font-family: $font-family--heading;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: -80px;
            margin: auto;
            width: 300px;
            height: 300px;
            border-radius: $border-radius--round;
            background-color: $color-third;
        }
    }

    &__link {
        display: flex;
        align-items: center;

        & svg {
            border-radius: $border-radius--round;
            background-color: $color-third--rgba;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus, &:focus-within {

            & svg {
                background-color: rgba(221,148,26,.5);
            }
        }
    }

    &__info {
        font-size: 1.125rem;
        line-height: 1.375rem;
        font-family: $font-family--heading;
        font-weight: $font-weight-semibold;
        margin-left: 10px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    // ALU
    .home-slideshow {
        .slideshow {
            width: calc(50% + 150px);

            &__content {
                left: 100px;
            }

            &__pagination {
                width: 620px;
            }
        }
    }

    // Access
    .access {

        &__list {
            height: 215px;
        }

        &__item {
            &:first-child {
                .access__link {
                    & svg {
                        width: 150px;
                        height: 150px;
                    }
                }
            }
        }

        &__link {
            & svg {
                width: 100px;
                height: 100px;
            }
        }
    }

    // Events
    .events {

        &__container {
            .swiper-container {
                height: 390px;

                .swiper-slide {
                    width: 300px;
                    margin-right: 20px;
                }
            }
        }

        &__heading {
            &:before {
                width: 250px;
                height: 250px;
                right: -85px;
            }
        }

        &__link {
            width: 300px;
            height: 370px;
        }

        &__title {
            font-size: 2.5rem;
            line-height: 3rem;
        }
        
        &__content {
            height: 170px;
        }

        &__date {
            top: 130px;
        }

        &__infos {
            padding: 80px 15px 15px;

            &__title {
                font-size: 1.563rem;
                line-height: 2rem;
            }
        }
    }

    // Post
    .post {

        &__section {
            padding: 80px 0 85px;
        }

        &__container {
            .swiper-container {
                height: 315px;
                margin-bottom: 20px;
            }

            &:before, &:after {
                width: 250px;
                height: 250px;
            }

            &:before {
                left: -95px;
                top: 50px;
            }

            &:after {
                right: -80px;
                bottom: -155px;
            }
        }

        &__content {
            width: 540px;
            height: 315px;
        }

        &__heading {
            width: 380px;
            top: 5px;
        }

        &__title {
            margin: 0;
            font-size: 2.5rem;
            line-height: 3rem;
        }

        &__infos {
            width: 380px;
            margin-top: 100px;
            margin-left: 20px;

            &__title {
                margin: 0;
            }

            &__introduction {
                margin-bottom: 15px;
            }
        }

        &__pagination {
            width: 540px;
            padding-left: 10px;
        }
    }

    // Press
    .press {

        &__subtitle {
            margin-top: -15px;
        }

        &__container {
            gap: 20px;
        }

        &__title {
            font-size: 2.5rem;
            line-height: 3rem;
        }

        &__item {
            width: calc(50% - 170px);

            picture {
                img {
                    height: 185px;
                }
            }
        }
    }

    // Section double : Kisoque + Annuaire
    .section-double {
        &:before {
            width: 250px;
            height: 250px;
            right: -100px;
        }
    }

    // Kiosque
    .kiosque {

        &__section {
            width: 620px;
            margin-top: 30px;
        }

        &__title {
            margin: 0 0 50px;
            font-size: 2.5rem;
            line-height: 3rem;
        }

        &__items {
            gap: 20px;
        }

        &__image {
            width: 140px;
            height: 200px;

            & img {
                height: 200px;
            }
        }

        &__info {
            &__title {
                margin: 0 0 5px;
                padding: 0;
            }

            &__content {
                margin: 0 0 12px;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 150px;
            margin-left: 10px;
        }

        &__buttons {
            width: 130px;
            gap: 5px;
        }

        &__btn {
            width: 40px;
            height: 40px;
        }

        &__button {
            width: 250px;
            padding: 15px 0;
            margin: 40px 0 0;
            font-size: 1rem;
        }
    }

    // Annuaire
    .annuaire {
        &__section {
            width: 300px;
            height: 380px;
            padding: 20px;
            margin-left: 20px;
            margin-top: 55px;
        }

        &__title {
            font-size: 2.5rem;
            line-height: 3rem;
        }

        &__dropdown {
            text-overflow: ellipsis;
            padding: 0 20px;
        }

        &__label {
            & br {
                display: none;
            }
        }

        &__template {
            &-btn {
                padding: 15px 5px;
            }
        }
    }

    // Keep-contact
    .keep-contact {

        &__title {
            font-size: 2.188rem;
            line-height: 2.188rem;
            margin: 40px 0 40px 5px;
            width: 165px;
            padding: 0;

            &:before {
                left: -45px;
                width: 250px;
                height: 250px;
            }
        }

        &__container {
            width: 940px;
        }

        &__list {
            width: 690px;
            gap: 80px;
            padding-left: 20px;
        }

        &__link {
            & svg {
                width: 50px;
                height: 50px;
            }
        }

        &__info {
            font-size: 1rem;
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    // ALU
    .home-slideshow {
        position: relative;

        &:before {
            height: 45px;
        }

        .slideshow {
            width: 100%;
            height: 440px;

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }

            &__content {
                left: 20px;
                margin-bottom: 20px;
                border-radius: 10px 10px 0 0;
            }

            &__pagination {
                width: 100%;
            }
        }
    }

    // Access
    .access {

        &__section {
            margin-top: 10px;
        }

        &__list {
            height: 425px;
            width: 505px;
            flex-wrap: wrap;
            margin: 0 auto;
        }

        &__item {
            text-align: center;
            margin-right: 80px;

            &:nth-child(1) {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                height: 215px;
                margin-right: 65px;
            }

            &:nth-child(3), &:nth-child(6) {
                margin: 0;
            }

            &:nth-child(4) {
                margin-left: 25px;
            }

            &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                margin-bottom: 45px;
            }
        }
    }

    // Events
    .events {
        &__container {
            &--pagination {
                z-index: 1;
            }

            .swiper-container {
                height: 450px;
            }
        }

        &__pagination {
            width: 100%;
            height: 18px;
            bottom: 90px;
            margin: auto;
        }

        &__button {
            margin: 0 auto;
        }
    }

    // Post
    .post {
        &__section {
            padding: 60px 0 100px;

            &:before {
                background-position-x: calc(50% - 300px);
                width: 1800px;
                height: 53px;
            }
        }

        &__heading {
            position: initial;
        }

        &__title {
            padding: 0;
            margin: 0 0 35px;
        }

        &__container {
            .swiper-container {
                height: auto;
                margin-bottom: 30px;
            }

            &:before {
                left: -125px;
                top: 40px;
            }

            &:after {
                right: -110px;
                top: 290px;
                bottom: unset;
            }

            &--pagination {
                position: unset;
            }
        }

        &__link {
            flex-direction: column;
        }

        &__content {
            width: 620px;
            height: 360px;
        }

        &__infos {
            width: 100%;
            margin: 70px auto 0;
            text-align: center;
        }

        &__pagination {
            width: 100%;
            height: 18px;
            bottom: unset;
            top: 475px;
            padding-left: 0;
        }

        &__buttons {
            text-align: center;
        }
    }
    

    // Press
    .press {

        &__subtitle {
            margin: -15px 0 0 0;
        }

        &__item {
            width: calc(calc(100% - 40px) / 3);
        }
    }

    // Section double : Kiosque + Annuaire
    .section-double {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    // Kiosque
    // .kiosque {
    //     &__section {
    //         width: 100%;
    //         height: 440px;
    //         margin-top: 40px;
    //     }

    //     &__image {
    //         width: 300px;
    //         height: 440px;

    //         & img {
    //             height: 440px;
    //         }
    //     }

    //     &__content {
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: space-between;
    //         width: 300px;
    //         margin-left: 20px;
    //     }

    //     &__title {
    //         margin: 55px 0 0;
    //     }

    //     &__info {
    //         &__title, &__content {
    //             margin: 0;
    //         }
    //     }

    //     &__buttons {
    //         margin: 0;
    //     }
    // }

    // Annuaire
    .annuaire {
        &__section {
            width: 100%;
            height: 344px;
            margin: 50px 0 0;
        }

        &__label {
            br {
                display: none;
            }
        }
    }

    // Restons en contact
    .keep-contact {
        &__container {
            width: 620px;
            height: 200px;
            margin-bottom: -100px;
            margin-top: 90px;
        }

        &__title {
            margin-left: 10px;

            &:before {
                left: -65px;
            }
        }

        &__list {
            width: 405px;
            gap: 20px 52px;
            padding-left: 20px;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }
}

// 800
@media screen and (max-width: 800px) {

    // Press
    .press {

        &__container {
            flex-direction: column;
            width: 100%;
        }

        &__item {
            width: 100%;

            picture {
                img {
                    height: 240px;
                }
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {

    // ALU
    .home-slideshow {
        .slideshow {
            height: 330px;

            &.swiper-container {
                overflow: visible;
            }

            &:before {
                height: 35px;
            }
            
            &__content {
                left: 30px;
                right: 30px;
                width: auto;
                margin-bottom: 0;
            }

            &__pagination {
                width: 100%;
                bottom: -38px;
                justify-content: center;
                pointer-events: auto;
                filter: none;

                .swiper-pagination-bullet {
                    border-color: $color-main-bg;
                    background-color: $color-main-bg;

                    &-active, &:hover {
                        background-color: $color-bg--transparent;
                    }
                }
            }
        }
    }

    // Access
    .access {

        &__section {
            margin-top: 80px;
        }

        &__list {
            height: 615px;
            width: 100%;
        }

        &__item {
            height: 165px;
            width: 150px;
            margin: 0 0 35px;

            &:first-child, &:nth-child(2), &:nth-child(3) {
                margin: 0 0 35px;
            }

            &:nth-child(4) {
                margin-left: 0;
            }

            &:nth-child(5), &:nth-child(6) {
                margin-bottom: 0;
            }
        }

        &__link:hover &__info {
            margin-top: 15px;
        }
    }

    // Post
    .post {

        &__section {
            padding: 80px 0 85px;

            &:before {
                background-position-x: center;
                width: 100%;
                height: 35px;
            }
        }

        &__heading {
            width: 100%;
        }

        &__container {
            &:before {
                left: -135px;
                top: 135px;
            }

            &:after {
                content: none;
            }

        }

        &__content {
            width: 100%;
            height: 200px;
        }

        &__infos {
            margin-top: 80px;
        }

        &__pagination {
            top: 320px;
        }
    }

    // Kiosque
    .kiosque {
        &__section {
            width: 100%;
            height: auto;
            margin-top: 125px;
        }

        &__items {
            flex-direction: column;
            gap: 30px;
        }

        &__image {
            margin-bottom: 0;
        }

        &__content {
            margin: 0 0 0 10px;
        }

        &__title {
            position: absolute;
            top: 30px;
            width: 100%;
            padding: 0 10px;
            margin: 0;
        }

        &__info {
            &__title {
                margin-bottom: 5px;
            }

            &__content {
                margin-bottom: 10px;
            }
        }

        &__button {
            width: 270px;
        }
    }

    // Annuaire
    .annuaire {

        &__section {
            margin-top: 60px;
            height: 500px;
        }

        &__title {
            padding-right: 45px;
        }

        &__label {
            & br {
                display: none;
            }
        }

        &__wrapper-select {
            flex-direction: column;

            & svg {
                right: 10px;
            }
        }

        &__dropdown {
            height: 60px;
            border-radius: 30px;
            margin-bottom: 15px;
        }

        &__btn {
            border-radius: 30px;
            width: 100%;
        }

        &__template {
            &-btn {
                padding: 15px 10px;
            }
        }
    }

    // Keep-contact
    .keep-contact {
        &__container {
            flex-direction: column;
            justify-content: unset;
            width: 300px;
            height: fit-content;
            padding: 20px 20px 35px;
            margin-bottom: -280px;
        }

        &__title {
            margin: 0;
            text-align: center;
            width: 100%;

            &:before {
                left: -30px;
                width: 320px;
                height: 320px;
                border-radius: 85px;
                margin: -215px auto;
            }
        }

        &__list {
            width: 100%;
            height: auto;
            gap: 15px 46px;
            margin-top: 65px;
            padding-left: 8px;
        }

        &__info {
            & br {
                display: none;
            }
        }
    }
}