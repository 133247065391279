header {
    position: absolute;
    height: 520px;
    width: 100%;
    background-color: $color-main-bg;
}

.header-top {
    height: 0;

    .container--identity {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 90px;

        .site-identity {
            position: relative;
            display: flex;
            align-items: center;
            height: 98px;
            margin-top: 15px;
            margin-left: calc(50% - 290px);
            z-index: 15;

            &:before{
                content: "";
                position: absolute;
                z-index: -1;
                width: 300px;
                height: 300px;
                left: -50px;
                right: -50px;
                bottom: -50px;
                margin: 0 auto;
                border-radius: 50%;
                background-color: $color-white;
            }
        }

        .identity {
            &__title {
                font-size: 0;
                line-height: 0;
                text-transform: uppercase;
                color: $color-text;
                margin: 0;
                transition: $duration;

                &:before {
                    content: none;
                }

                &:hover {
                    cursor: pointer;
                    color: $color-second;
                }
            }
        }

    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

body:not(.home) {
    .tools {
        &__content {
            & > svg {
                fill: $color-bg--neutral;
            }
        }
        
        &::before {
            background: $color-bg--neutral;
        }
    }
}

.tools {
    position: absolute;
    top: 0;
    z-index: 2;

    .container--tools {
        display: flex;
        justify-content: flex-start;
    }

    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: center;
        height: 40px;
        margin-top: 40px;
        margin-bottom: 0;
        background: $color-bg--transparent;

        & > svg {
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            fill: $color-white; 
        }
    }

    .tool {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 0;
        padding: 0;
        color: $color-third;
        font-family: $font-family--heading;
        background-color: $color-bg--transparent;
        border: 0;
        transition: all $duration;
        
        svg {
            width: 40px;
            height: 40px;
            margin-right: 15px;
            fill: $color-third;
            border: 1px solid $color-third;
            border-radius: $border-radius--round;
            transition: all $duration;
        }

        &:hover, &:focus, &:focus-within {
            cursor: pointer;
            color: $color-white;

            svg {
                fill: $color-dark;
                background-color: $color-third;
            }
        }
    }

    &__search {
        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
    }    

    &__app {
        & svg {
            margin-right: 5px!important;
        }

        &__label {
            line-height: 1.25rem;
        }
    }
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}



//=================================================================================================
// Scroll
//=================================================================================================
.header--fixed {

    .header-top {
        position: relative;
        z-index: 100;

        .container {
            &--identity {
                position: fixed;
                margin: 0 auto;
                left: 0;
                right: 0;
                height: 70px;
                padding-top: 10px;

                .site-identity {
                    display: none;
                }
            }
        }
    }

    .tools {

        .tool {
            height: 60px;
            transition: none;
        }

        &__content {
            margin-top: 10px;
        }

        &__accessibility, &__app {
            display: none;
        }

        &__search {
            position: fixed;
            height: 60px;
            width: 60px;
            left: calc(50% - 400px);
            background-color: $color-main-bg;
            border: 1px solid $color-main;
            transition: all $duration ease-in-out;

            & svg {
                margin: auto;
                border: 0;
                fill: $color-white;
            }

            &:hover {
                background-color: $color-third;
                border-color: $color-third;

                & svg {
                    background-color: $color-bg--transparent;
                    fill: $color-text;
                }
            }
        }
    }

    #nav-main {
        position: fixed;
        width: 100%;
        margin: 10px 0 0;
        z-index: 100;

        .container--nav {
            align-items: flex-start;
            height: fit-content;
            flex-direction: column;
        }

        .menu__nav__list {
            position: initial;
            height: 480px;
            width: 380px;
            padding-top: 140px;
            padding-left: calc((100% - 1170px) / 2);
            transform: translate(-15px);
            opacity: 0;
            pointer-events: auto;
            visibility: hidden;
            transition: none;
            margin-top: -60px;
            z-index: 14;

            > li {
                display: flex;
                padding: 30px 60px 10px 0;

                .nav-main-link {
                    display: inline-block;
                    width: 100%;
                    margin: 0;
                }

                &:hover {
                    width: auto;
                }

                &.menu-item-has-children {
                    .sub-menu {
                        height: auto;
                        min-height: 500px;
                        top: 0;
                        left: 380px;
                        align-items: center;
                        padding: 80px 0 0;
                        background: $color-main-bg;
                        //left: calc(50% + 380px);
                        left: 100%;
                        height: 500px;
                        position: absolute;
                        overflow: visible;

                        ul {
                            width: inherit;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            width: 0;
                            left: 0;
                            bottom: 0;
                        }
                    }
                    &:hover, &:focus-within {
                        .sub-menu {
                            width: 100%;
                            opacity: 1;

                            &:before {
                                height: 500px;
                                width: 100vw;
                                background-color: $color-main-bg;
                            }
                        } 
                    }
                }
            }
        }

        .js-menu-toggled {
            position: absolute;
            width: calc(50% - 160px);
            left: 0;
            height: 500px;
            background-color: $color-main-bg;
            transform: translate(0);
            opacity: 1;
            visibility: visible;
            transition: all .3s ease-out;
        }
    }

    .nav-main_button {

        ~ .nav-main_icon {
            opacity: 1;
            z-index: 102;
            width: 170px;
            display: flex;
            margin: 0;
            height: 60px;
            pointer-events: auto;
            justify-content: center;
            background-color: $color-main-bg;
            border: 1px solid $color-main;
            font-family: $font-family--heading;
            font-size: 1.5rem;
            font-weight: $font-weight;
            border-radius: 30px;
            text-transform: uppercase;
            visibility: visible;
            transition: all .3s ease-out;
        }

        &:checked {
            ~ .nav-main_icon {
                .navicon {
                    background: transparent;
                    &:before {
                            transform: rotate(-45deg);
                    }
                    &:after {
                            transform: rotate(45deg);
                    }
                }
                &:not(.steps) {
                    .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                    }
                }
            }
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .header-top {
        .container {
            &--identity {
                .site-identity {
                    margin-left: calc(50% - 250px);
                }
            }
        }
    }

    .tools {
        &__app {
            &__label {
                display: none;
            }

            & svg {
                margin-right: 0;
            }
        }
    }

    .header--fixed {

        .tools {
            .tool {
                left: calc(50% - 280px);
            }
        }

        #nav-main {
            .menu__nav__list {
                width: calc(50% - 140px);
                padding-left: calc(50% - 470px);

                > li {
                    padding: 30px;

                    &.menu-item-has-children {
                        .sub-menu {
                            //left: calc(50% + 220px);
                            left: 100%;
                        }

                        &:hover {
                            .sub-menu {
                                width: 630px;
                            }
                        }
                    }
                }
            }

            .nav-main-item {
                .sub-menu {
                    ul {
                        width: 640px;
                        padding: 40px 20px 0;
                    }

                    .nav-child-item, .nav-grandchild-item {
                        width: 280px;
                        padding: 0 0 5px;
                    }
                }
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    header {
        position: initial;
        height: 80px;
    }

    .header-top {
        .container {
            &--identity {
                position: relative;
                height: 80px;

                .site-identity {
                    height: 92px;
                    margin: 15px auto 0;

                    &:before {
                        width: 280px;
                        height: 280px;
                        bottom: -50px;
                    }
                }

                .identity {
                    &__title {
                        & svg {
                            width: 185px;
                            height: 92px;
                        }
                    }
                }
            }
        }
    }

    .tools {
        left: 10px;

        &__content {
            height: 80px;
            margin: 0;
        }
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    header {
        height: 205px;
    }

    .header-top {
        
        .container {
            &--identity {
                .site-identity {
                    margin-top: 10px;
                    height: 75px;

                    &:before {
                        width: 225px;
                        height: 225px;
                        bottom: -40px;
                    }
                }

                .identity {
                    &__title {
                        & svg {
                            width: 150px;
                            height: 75px;
                        }
                    }
                }
            }
        }
    }

    .tools {
        top: 125px;

        .tool {
            margin: 0 15px 0 0;

            svg {
                margin: 0;
            }

            &:last-child {
                margin: 0;
            }
        }
    }
}
