.footer {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 165px;
    height: 577px;
    background-color: $color-main-bg;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: calc(50% - 115px);
        height: 577px;
        background-image: url('/wp-content/themes/saclay/assets/src/images/Carte/Carte.jpg');
        background-size: cover;
    }

    &__filigrane {
        position: absolute;
        top: -70px;
        left: -165px;
        width: 194px;
        height: 395px;
        fill: $color-white;
    }

    &__container {
        position: relative;
        width: 100%;
        margin-bottom: 35px;
        
        &__infos {
            width: calc(50% + 115px);
        }
    }

    &__infos {
        display: flex;
        height: 295px;
        padding: 0;
        color: $color-white;

        &__logo {
            display: inline-block;
            opacity: 1;
            transition: all $duration ease-in-out;

            &:hover {
                opacity: .6;
            }
        }

        &__phone {
            color: $color-white;

            &:hover {
                color: $color-white;
                opacity: .6;
            }
        }

        &__title {
            color: $color-white;
            font-size: 1.375rem;
            line-height: 1.5rem;
            font-weight: $font-weight-semibold;
            font-family: $font-family--heading;
            margin: 0 0 15px;
        }
        
        &__coordonnees {
            display: flex;
            flex-direction: column;
            width: 200px;
            margin-right: 100px;

            .button {
                margin: 35px 0 30px 0;
                padding: 15px;

                &.button-template-contact {
                    background-color: $color-white;
                    border-color: $color-white;

                    & svg {
                        margin-right: 5px;
                    }

                    &:hover {
                        background-color: $color-bg--transparent;
                        color: $color-white;

                        & svg {
                            fill: $color-white;
                        }
                    }
                }

                &.button-label {
                    margin: 0;
                    background-color: $color-fourth;
                    border-color: $color-fourth;
                    color: $color-text;

                    & svg {
                        fill: $color-text;
                        margin-right: 5px;
                    }

                    &:hover {
                        background-color: $color-bg--transparent;
                        border-color: $color-fourth;
                        color: $color-fourth;

                        & svg {
                            fill: $color-fourth;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        &__horaires {
            width: 335px;

            .footer__infos__text {
                margin-bottom: 75px;
            }
        }
    }

    &__map-img {
        position: absolute;
        top: 170px;
        left: 635px;
    }

    &__map-link {
        display: inline-flex;
        align-items: center;
        color: $color-third;
        position: absolute;
        right: 0;
        bottom: 35px;
        font-size: 1.125rem;
        line-height: 1.375rem;
        font-family: $font-family--heading;
        font-weight: $font-weight-semibold;
        transition: all $duration ease-in-out;

        & svg {
            width: 25px;
            height: 25px;
            margin-right: 10px;
            fill: $color-third;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            color: $color-white;

            & svg {
                fill: $color-white;
            }
        }
    }

    &__menu {
        padding: 45px 0 35px;

        ul {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 10px 40px;
            width: 560px;
        }

        li {
            position: relative;
            padding: 0;
            font-size: 0.875rem;

            a {
                color: $color-white;
                opacity: .6;

                &:hover {
                    opacity: 1;
                }
            }

            // &:first-child, &:nth-child(4) {
            //     padding-left: 0;
            // }

            // &:last-child {
            //     padding-right: 0;
            // }
        }
    }
}


// 1200
@media screen and (max-width: $large) {

    .footer {

        &:before {
            width: calc(50% - 80px);
        }

        &__filigrane {
            top: -35px;
            left: -135px;
            width: 170px;
            height: 345px;
        }

        &__container {
            &__infos {
                width: calc(50% + 105px);
            }
        }

        &__infos {
            &__coordonnees {
                margin-right: 40px;
            }
        }

        &__map-img {
            left: calc(50% + 5px);
        }

        &__menu {
            width: 610px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .home {
        .footer {
            padding-top: 165px;
            height: 577px;
        }
    }

    .footer {
        padding-top: 80px;
        height: auto;

        &:before {
            content: none;
        }

        &__container {
            &__infos {
                width: 100%;
            }
        }

        &__infos {
            height: 295px;

            &__coordonnees {
                margin-right: 55px;

                .button {
                    margin-top: 34px;
                }
            }

            &__logo {
                height: 62px;
            }

            &__horaires {
                width: 365px;

                & div {
                    display: flex;
                    justify-content: flex-end;
                    position: relative;
                    margin-top: -5px;
                    width: 100%;
                
                    .footer {
                        &__map-img {
                            top: -120px;
                            left: unset;
                            right: 40px;
                            width: 110px;
                            height: 110px;
                        }

                        &__map-link {
                            z-index: 1;
                        }
                    }
                }
            }

            &__title {
                line-height: 1.5rem;
            }
        }

        &__menu {
            width: 100%;

            ul {
                justify-content: center;
                margin: 0 20px;
                width: 100%;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {

    .home {
        .footer {
            height: auto;
            padding-top: 385px;
        }
    }

    .footer {
        height: auto;

        &__container {
            margin-bottom: 15px;
        }

        &__filigrane {
            top: -25px;
            left: -85px;
        }

        &__infos {
            flex-direction: column;
            align-items: center;
            height: auto;

            &__coordonnees {
                margin: 0 0 50px;
                text-align: center;
            }

            &__horaires {
                width: 100%;
                text-align: center;

                & div {
                    margin-top: 40px;
                    width: 100%;
                    flex-direction: column;
                    align-items: center;
                }
            }

            &__text {
                margin-bottom: 40px!important;
            }
        }

        &__map-img {
            position: initial;
        }

        &__map-link {
            left: 0;
            margin: 0 auto;
            justify-content: center;
        }

        &__menu {
            padding-top: 50px;
            
            ul {
                flex-wrap: wrap;
                width: unset;
                margin: 0;
                row-gap: 15px;

                // li {
                //     // padding: 0 20px 15px!important;

                //     // &:nth-child(4) {
                //     //     padding-left: 20px;
                //     // }

                //     // &:last-child {
                //     //     padding-right: 20px;
                //     // }
                // }
            }
        }
    }
}