.post_navigation {
    // display: flex;
    // justify-content: center;
    padding: 80px 0 40px;
    background: $color-bg--neutral;

    .return {
        a {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            color: $color-main;
            transition: all $duration;

            svg {
                width: 26px;
                height: 26px;
                margin-right: 5px;
                fill: $color-main;
                transition: all $duration;
            }

            &:hover {
                opacity: .6;

                svg {
                    opacity: .6;
                }
            }
        }
    }
}

// .navigation_link svg {
//     position: relative;
//     top: 5px;
// }

// Update Upgrades 11/2023
// for all buttons navigations
.navigation_link {
    column-gap: 5px;
}

.post_navigation {
    &--container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        // Si les liens sont cachés, mettre ce style :
        // justify-content: space-evenly;
        width: 100%;
        gap: 30px;
    }

    .return {
        a {
            align-items: center;
        }
    }

    .prev, .next {
      // A adapter en fonction de la width du bouton précédent
      min-width: 115px;

        .navigation_link {
            color: $color-main;

            // Recup style ~ ligne 9
            a {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                font-size: $font-size--text-small;
                font-weight: $font-weight-bold;
                color: $color-main;
                transition: all $duration;
            }
        }
    }

    .prev {
        .navigation_link {
          display: flex;
          justify-content: flex-start;

            a {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-main;
                    mask-image: url( $urlShort + '/ic-nav-arrow-left.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-right: 5px;
                }
            }
        }
    }

    .next {
        .navigation_link {
             display: flex;
            justify-content: flex-end;

          a {
                &:after {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-main;
                    mask-image: url( $urlShort + '/ic-nav-arrow-right.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-left: 5px;
                }
            }
        }
    }

    .prev, .next {
        &:hover, &:focus, &:focus-within {

            .navigation_link {
                a {
                    opacity: .6;

                    &:before, &:after {
                        opacity: .6;
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .post_navigation {
        .return {
            display: flex;
            justify-content: center;
            order: 1;
            flex: 1 1 100%;
        }
    }
}
