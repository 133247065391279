.cover {
    position: relative;
    margin: 0;

    &:before {
        content: "";
        position: absolute;
        z-index: 2;
        height: 62px;
        width: 100%;
        top: 458px;
        left: 0;
        right: 0;
        background-image: url('/wp-content/themes/saclay/assets/src/images/sprite-images/Pictos/Vague-grey.svg');
        background-position-x: center;
        background-size: cover;
        pointer-events: none;
    }

    &__image-wrapper {
        width: calc(50% + 185px);
        height: 520px;
        margin-right: 0;
        margin-left: auto;
        background: $color-main-bg;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        background: $color-bg--neutral;
        padding: 15px 0 60px;
    }

    &__title {
        margin: 0 0 10px;
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-weight: $font-weight-bold;
            font-size: $font-size--5;
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__image-wrapper {
            width: calc(50% + 150px);
        }
    }
    
}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            width: 100%;
            height: 520px;
        }

        &__informations {
            padding: 15px 0 40px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {     
        &:before {
            height: 35px;
            top: 295px;
        }  

        &__image-wrapper {
            height: 330px;
        }
    }

}
